import React from "react";
// import TokenImg from "../../Assets/token.png";
import MobTokenImg from "../../Assets/mob-token.png";
import './token.css'
import LightImg from '../../Assets/elements/light-line.svg'
import LottieToken from "./LottieToken";
function Tokenomic() {
  return (
    <>
      <section className="toko_system" id="tokonomic">
        <div className="container">
          <div className="header-text text-center">
            <h2> </h2>
            <hr className="low-hr" />
          </div>

          <div className="">
          { }
          <div className="token text-center">
            {/* <img src={TokenImg} alt=""  className="pc-tok"/> */}
            <img src={MobTokenImg} alt="" className="mob-tok"/>
           <div className="pc-tok">
           <LottieToken/>
           </div>
            
          </div>

          {/* <div className="token_details">
            <p className="team-1">Team Vesting 5%</p>
            <p className="stake-1">Staking 20%</p>
            <p className="exchange">Exchange Listings 15%</p>
          </div> */}



          </div>
         
        </div>

     
      </section>
    </>
  );
}

export default Tokenomic;
