import React from "react";
import "./Hero.css";

// import Mainbg from '../../Assets/video.mp4'
import VideoTop from "../../Assets/videotop.webp";

import { MdQrCode } from "react-icons/md";
function Hero() {
  return (
    <section className="nsf-bg">
       {/* <video
       playsInline
       loop
       autoPlay
       muted
        src={Mainbg}
        id='main-vid'
           
      />     */}
      <img src={VideoTop} alt="" className="nsf-bg" style={{margin: 'auto'}} />

    </section>
  );
}

export default Hero;
