import React from "react";
import './Gal.css'
import GalSwipe1 from "./GalSwipe1";
import GalSwipe2 from "./GalSwipe2";
import BgBack from '../../Assets/elements/black.png'
import MobGallery from "./MobGallery";

function Gallery() {
  return (
    <section className="gallery">
       <br id="gallery"/>



  
      <MobGallery/>
      <GalSwipe1/>

      <GalSwipe2/>
    </section>
  );
}

export default Gallery;
