import React, { useState } from "react";
import One from "../../Assets/So/1.jpg";
import Play from "../../Assets/So/2.jpg";
import Nft from "../../Assets/So/3.jpg";
import Nft2 from "../../Assets/So/4.jpg";
import last from "../../Assets/So/5.jpg";

function EcoList() {
  const [display, setDisplay] = useState("notdisplayed");

  const [display1, setDisplay1] = useState("notdisplayed");
  const [display2, setDisplay2] = useState("notdisplayed");
  const [display3, setDisplay3] = useState("notdisplayed");
  const [display4, setDisplay4] = useState("notdisplayed");

  const showButton = (e) => {
    e.preventDefault();
    setDisplay("displayed");
  };

  const hideButton = (e) => {
    e.preventDefault();
    setDisplay("notdisplayed");
  };

  const showButton1 = (e) => {
    e.preventDefault();
    setDisplay1("displayed");
  };

  const hideButton1 = (e) => {
    e.preventDefault();
    setDisplay1("notdisplayed");
  };

  const showButton2 = (e) => {
    e.preventDefault();
    setDisplay2("displayed");
  };

  const hideButton2 = (e) => {
    e.preventDefault();
    setDisplay2("notdisplayed");
  };

  const showButton3 = (e) => {
    e.preventDefault();
    setDisplay3("displayed");
  };

  const hideButton3 = (e) => {
    e.preventDefault();
    setDisplay3("notdisplayed");
  };

  const showButton4 = (e) => {
    e.preventDefault();
    setDisplay4("displayed");
  };

  const hideButton4 = (e) => {
    e.preventDefault();
    setDisplay4("notdisplayed");
  };

  return (
    <section className="eco-list">
      <div className="container">
        <div className="eco-list-grid">
          <div
            className="eco-box"
            data-aos="fade-up"
            data-aos-duration="1000"
            onMouseEnter={(e) => showButton(e)}
            onMouseLeave={(e) => hideButton(e)}
          >
            <img src={One} alt="" />
          </div>

          <div
            className="eco-box"
            data-aos="fade-up"
            data-aos-duration="1000"
            onMouseEnter={(e) => showButton1(e)}
            onMouseLeave={(e) => hideButton1(e)}
          >
            <img src={Play} alt="" />
          </div>
          <div
            className="eco-box"
            data-aos="fade-up"
            data-aos-duration="2000"
            onMouseEnter={(e) => showButton2(e)}
            onMouseLeave={(e) => hideButton2(e)}
          >
              <img src={Nft} alt="" />
          </div>


          <div
            className="eco-box"
            data-aos="fade-up"
            data-aos-duration="2000"
            onMouseEnter={(e) => showButton3(e)}
            onMouseLeave={(e) => hideButton3(e)}
          >
              <img src={Nft2} alt="" />
          </div>


          <div
            className="eco-box"
            data-aos="fade-up"
            data-aos-duration="3000"
            onMouseEnter={(e) => showButton4(e)}
            onMouseLeave={(e) => hideButton4(e)}
          >
              <img src={last} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default EcoList;
